<template>
  <div class="warp">
    <el-form inline size="mini">
      <el-form-item label="Source of Data:">
        <select-box
          width="200"
          :options="originData"
          :value.sync="extraData.dataSourceCode"
          placeholder="Please select source of data"
        />
      </el-form-item>

      <el-form-item label="Status:">
        <select-box
          width="160"
          :options="statusOptions"
          :value.sync="extraData.status"
        />
      </el-form-item>
      <el-form-item label="Source ID:">
        <el-input
          style="width: 240px"
          clearable
          v-model="extraData.sourceId"
          placeholder="Please typing in source ID"
        ></el-input>
      </el-form-item>
      <el-form-item label="Source Name:">
        <el-input
          style="width: 200px"
          clearable
          v-model="extraData.thirdName"
          placeholder="Please typing in source name"
        ></el-input>
      </el-form-item>

      <el-form-item label="Standard Name:">
        <input-autocomplete
          :extraData.sync="extraData.standardName"
          :search-key="isPlayer ? 'STD_SEARCH_PLAYER' : 'STD_SEARCH_TEAM'"
          value-key="name"
          label-key="id"
          query-key="name"
          :queryOrderParams="{ page: 1, size: 100 }"
        />
      </el-form-item>
      <el-form-item label="Source Sport:">
        <!-- <input-autocomplete
          :extraData.sync="extraData.thirdSportsName"
          search-key="STD_SEARCH_SPORT"
          value-key="sportsName"
          label-key="id"
          query-key="name"
          :queryOrderParams="{ page: 1, size: 500 }"
        /> -->
        <select-box
          :url="STD_SEARCH_SPORT"
          :remote="false"
          :queryOrderParams="{ page: 1, size: 500 }"
          :value.sync="extraData.sportId"
          placeholder="Select"
          label-key="sportsName"
          value-key="id"
        />
      </el-form-item>
      <el-form-item label="Source Region:" v-if="!isPlayer">
        <el-input
          style="width: 200px"
          clearable
          v-model="extraData.thirdRegionName"
          placeholder="Please typing in source region name"
        />
      </el-form-item>
      <el-form-item label="Source Tournament:" v-if="!isPlayer">
        <el-input
          style="width: 200px"
          clearable
          v-model="extraData.thirdTournamentName"
          placeholder="Please typing in source tournament name"
        />
      </el-form-item>
      <el-form-item label="Source Tournament:" v-if="isPlayer">
        <input-autocomplete
          :extraData.sync="extraData.sourceTournamentName"
          search-key="STD_SEARCH_TOURNAMENT"
          :queryOrderParams="{ page: 1, size: 100 }"
          value-key="name"
          label-key="id"
          query-key="name"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="search"
          >Search</el-button
        >
      </el-form-item>
    </el-form>
    <el-table
      :data="list"
      v-loading="loading"
      @sort-change="sort"
      :max-height="tableHeight"
    >
      <el-table-column label="Source of Data" width="140" align="center">
        <template v-slot="{ row }">
          {{ originMapping[row.dataSourceCode] }}
        </template>
      </el-table-column>
      <el-table-column
        class-name="yellow-col"
        label="source ID"
        width="140"
        align="center"
        prop="sourceTeamId"
        sortable="custom"
      >
      <template v-slot="{ row }">
          {{ isPlayer ? row.sourcePlayerId : row.sourceTeamId }}
        </template>
      </el-table-column>
      <el-table-column
        class-name="yellow-col"
        label="Standard ID"
        min-width="160"
        align="center"
        prop="standardTeamId"
        sortable="custom"
      >
      <template v-slot="{ row }">
          {{ isPlayer ? row.standardPlayerId : row.standardTeamId }}
        </template>
      </el-table-column>
      <el-table-column
        class-name="green-col"
        label="source Name"
        min-width="180"
        align="center"
        prop="thirdTeamName"
      >
        <template v-slot="{ row }">
          <div
            :class="{
              red:
                row.status && (!isPlayer ? compare(row.thirdTeamName, row.standardTeamName) : compare(row.sourcePlayerName, row.standardPlayerName))
            }"
          >
             {{ isPlayer ? row.sourcePlayerName : row.thirdTeamName }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        class-name="green-col"
        label="Standard Name"
        min-width="160"
        align="center"
        prop="standardTeamName"
      >
      <template v-slot="{ row }">
          {{ isPlayer ? row.standardPlayerName : row.standardTeamName }}
        </template>
      </el-table-column>
      <el-table-column
        class-name="yellow-col"
        label="source Sports"
        min-width="180"
        align="center"
        prop="thirdSportsName"
      >
        <template v-slot="{ row }">
          <div
            :class="{
              red:
                row.status &&
                (!isPlayer ? compare(row.thirdSportsName, row.standardSportsName) : compare(row.sourceSportName, row.standardSportName))
            }"
          >
          {{ isPlayer ? row.sourceSportName : row.thirdSportsName }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        class-name="yellow-col"
        label="Standard Sports"
        min-width="160"
        align="center"
        prop="standardSportsName"
      >
       <template v-slot="{ row }">
          {{ isPlayer ? row.standardSportName : row.standardSportsName }}
        </template>
      </el-table-column>
      <el-table-column
        class-name="green-col"
        label="Source Region"
        v-if="!isPlayer"
        min-width="160"
        align="center"
        prop="thirdRegionName"
      >
        <template v-slot="{ row }">
          <div
            :class="{
              red:
                row.status &&
                compare(row.thirdRegionName, row.standardRegionName)
            }"
          >
            {{ row.thirdRegionName }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        class-name="green-col"
        label="Standard Region"
        v-if="!isPlayer"
        min-width="160"
        align="center"
        prop="standardRegionName"
      ></el-table-column>
      <el-table-column
        class-name="yellow-col"
        label="Source Tournament"
        min-width="160"
        align="center"
        prop="thirdTournamentName"
      >
        <template v-slot="{ row }">
          <div
            :class="{
              red:
                row.status &&
                (!isPlayer ?compare(row.thirdTournamentName, row.standardTournamentName) : compare(row.sourceTournamentName, row.standardTournamentName))
            }"
          >
             {{ isPlayer ? row.sourceTournamentName : row.thirdTournamentName }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        class-name="yellow-col"
        label="Standard Tournament"
        min-width="180"
        align="center"
        prop="standardTournamentName"
      ></el-table-column>
      <el-table-column
        label="Status"
        align="center"
        prop="shortName"
        width="120"
      >
        <template v-slot="{ row }">
          <p :class="{ red: !row.status, green: row.status }">
            • {{ row.status ? 'Mapped' : 'UnMapped' }}
          </p>
        </template>
      </el-table-column>
      <el-table-column
        label="Remark"
        min-width="180"
        align="center"
        prop="remark"
      >
        <template v-slot="{ row }">
          <dropdown
            :row="{
              thirdId: row.thirdPlayerId || row.thirdTeamId,
              dataSourceCode: row.dataSourceCode,
              standardId: row.standardPlayerId || row.standardTeamId
            }"
            :methodStr="methodStr"
            :text="`${row.remark || 'Please select'}`"
            @get-list="getList"
          />
        </template>
      </el-table-column>
      <el-table-column
        label="Operation"
        min-width="100"
        align="center"
        prop="shortName"
      >
        <template v-slot="{ row }">
          <el-link type="primary" :underline="false" @click="edit(row)"
            >Edit</el-link
          >
        </template>
      </el-table-column>
      <el-table-column label="Log" min-width="160" align="center">
        <template v-slot="{ row }">
          <OperationType :dataType="isPlayer ? 'Third_Player':'Third_Team'" :dataId="row.thirdTeamId" />
        </template>
      </el-table-column>
    </el-table>
    <el-row type="flex" justify="end" style="margin-top: 20px">
      <el-pagination
        :total="total"
        :current-page="queryData.current"
        :page-size="queryData.size"
        :page-sizes="pageSizes"
        @current-change="changePage"
        @size-change="handleSizeChange"
        :layout="pageLayout"
      ></el-pagination>
    </el-row>
    <el-dialog
      :title="dialog.title"
      width="500px"
      :visible.sync="dialog.show"
      :close-on-click-modal="dialog.clickModal"
    >
      <el-form
        :model="dialogForm"
        label-width="164px"
        size="mini"
        :ref="dialog.ref"
      >
        <el-form-item label="Source Name:">
          {{ dialogForm.thirdName }}
        </el-form-item>
        <el-form-item label="Standard Sport:" prop="standardSportId" required>
          <select-box
            :url="STD_SEARCH_SPORT"
            :queryOrderParams="{ page: 1, size: 500 }"
            :value.sync="dialogForm.standardSportId"
            :label="dialogForm.standardSportsName"
            search-key="name"
            label-key="sportsName"
            value-key="id"
          />
        </el-form-item>
        <el-form-item  v-if="!isPlayer" label="Standard Region:" prop="regionId">
          <select-box
            :url="STD_SEARCH_REGION"
            :queryOrderParams="{
              page: 1,
              size: 500,
              sportId: dialogForm.standardSportId,
              deleteFlag: 1
            }"
            :value.sync="dialogForm.standardRegionId"
            :label="dialogForm.standardRegionName"
            search-key="name"
            label-key="name"
            value-key="id"
            @change="onSelectedDrug"
          />
        </el-form-item>
        <el-form-item label="Standard tournament:" prop="tournamentId">
          <select-box
            :url="STD_SEARCH_TOURNAMENT"
            :queryOrderParams="{
              page: 1,
              size: 100,
              sportId: dialogForm.standardSportId
            }"
            :value.sync="dialogForm.standardTournamentId"
            :label="dialogForm.standardTournamentName"
            search-key="name"
            label-key="name"
            value-key="id"
            @change="onSelectedDrug"
          />
        </el-form-item>
        <el-form-item label="Standard Name:" prop="standardId">
          <select-box
            :url="isPlayer ? STD_SEARCH_PLAYER : 'team/validList'"
            :queryOrderParams="{
              page: 1,
              size: 100,
              tournamentId: dialogForm.standardTournamentId,
              regionId: dialogForm.standardRegionId,
              sportId: dialogForm.standardSportId,
              isVisible: 1
            }"
            :value.sync="dialogForm.standardId"
            :label="dialogForm.standardName"
            search-key="name"
            label-key="name"
            value-key="id"
          />
        </el-form-item>
        <el-form-item label="Remark:" prop="remark">
          <el-input
            v-model="dialogForm.remark"
            placeholder="Please enter remark"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="dialogSubmit"
            :loading="dialog.isLoading"
            >Confirm</el-button
          >
          <el-button @click="dialogHide">Cancel</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  getTeamMappingList,
  playerMapping,
  playerUpdateMapping,
  editTeamMapping
} from '@/service/subscribe'
// import TableMixin from '@/mixins/standard/tableSelect'
import TableMixin from '@/mixins/subscibe/table'
import Dropdown from '@/components/Dropdown'
// import { originMapping } from '@/utils/constants'

export default {
  name: 'TeamMapping',
  mixins: [TableMixin],
  components: { Dropdown },
  data() {
    return {
      methodStr: 'editTeamMapping',
      isRed: false,

      extraData: {
        standardName: ''
      }
    }
  },
  computed: {
    isPlayer() {
      return this.$route.path.indexOf('player') > -1
    }
  },
  mounted() {
    this.methodStr = this.isPlayer ? 'playerUpdateMapping' : 'editTeamMapping'
  },
  methods: {
    getData(...rest) {
      const [params] = rest
      console.log(params)
      if (this.isPlayer) {
       return playerMapping(this.fliterParams(params, ['sourceId']))
      } else {
       return getTeamMappingList(this.fliterParams(params, ['sourceId']))
      }
    },
    editData(...rest) {
      if (this.isPlayer) {
       return playerUpdateMapping(...rest)
      } else {
       return editTeamMapping(...rest)
      }
    },
    edit(row) {
      let data
      if(this.isPlayer) {
        data = {
          thirdId: row.thirdPlayerId,
          standardId: row.standardPlayerId,
          standardName: row.standardPlayerName,
          remark: row.remark,
          standardTournamentId: row.standardTournamentId,
          sourceId: row.sourcePlayerId,
          standardTournamentName:
            row.standardTournamentName ||
            (row.standardTournamentId === undefined
              ? ''
              : `selected TournamentID:${row.standardTournamentId}`),
          thirdName: row.sourcePlayerName,
          standardSportId: row.standardSportId,
          dataSourceCode: row.dataSourceCode,
          standardSportsName: row.standardSportName
        }
      } else {
        data = {
        thirdId: row.thirdTeamId,
        standardId: row.standardTeamId,
        standardName: row.standardTeamName,
        remark: row.remark,
        standardRegionId: row.standardRegionId,
        standardRegionName: row.standardRegionName,
        standardTournamentId: row.standardTournamentId,
        sourceId: row.sourceTeamId,
        standardTournamentName:
          row.standardTournamentName ||
          (row.standardTournamentId === undefined
            ? ''
            : `selected TournamentID:${row.standardTournamentId}`),
        thirdName: row.thirdTeamName,
        standardSportId: row.standardSportId,
        dataSourceCode: row.dataSourceCode,
        standardSportsName: row.standardSportsName
      }
      }
      
      this.dialogEdit(data)
    },
    onSelectedDrug(e, item) {
      this.dialogForm.standardId = null
      this.dialogForm.standardName = null
    },
    compare(a, b) {
      try {
        a = a.trim()
      } catch (error) {}
      try {
        b = b.trim()
      } catch (error) {}
      return (a || '').toLowerCase() !== (b || '').toLowerCase()
    },
    async dialogSendData() {
      let data
      try {
        let params = {
          thirdId: this.dialogForm.thirdId,
          standardId: this.dialogForm.standardId,
          dataSourceCode: this.dialogForm.dataSourceCode,
          remark: this.dialogForm.remark,
          sourceId: this.dialogForm.sourceId
        }
        data = await this.editData(params)
        if (data.code === 0) {
          this.getList && this.getList()
          this.dialogHide()
          this.$message({
            message: 'Successful operation',
            type: 'success'
          })
        } else {
          this.dialog.isLoading = false
        }
      } catch (error) {
        this.dialog.isLoading = false
      }
    }
  }
}
</script>
